import React, { useEffect, useState, useRef, cloneElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect, sendEth } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

// export const StyledButton = styled.button`
//   padding: 10px;
//   border-radius: 50px;
//   margin-top: -75px;
//   border: none;
//   background-color: #0154c1;
//   padding: 10px;
//   font-weight: bold;
//   color: var(--secondary-text);
//   width: 110px;
//   height: 40px;
//   cursor: pointer;
//   box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
//   -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
//   -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
//   :active {
//     box-shadow: none;
//     -webkit-box-shadow: none;
//     -moz-box-shadow: none;
//   }
// `;
// export const StyledButton = styled.button`
//   padding: 10px;
//   border-radius: 60px;
//   margin-top: 100px;
//   border: none;

//   background-color: #032054;
//   font-weight: bold;
//   color: var(--secondary-text);
//   width: 220px;
//   height: 60px;
//   margin-bottom: 0px;
//   cursor: pointer;
//   box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
//   -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
//   -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);

//   &:hover {
//     background-color: #3e3f40;
//     box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.6);
//     -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.6);
//     -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.6);
//   }

//   &:active {
//     background-color: #002d6e;
//     box-shadow: none;
//     -webkit-box-shadow: none;
//     -moz-box-shadow: none;
//   }
// `;
// export const StyledButton = styled.button`
//   padding: 10px;
//   border-radius: 60px;
//   margin-top: 60px;
//   border: none;

//   background-color: rgb(51, 200, 225);
//   font-weight: 1000;
//   color: var(--secondary-text);
//   width: 220px;
//   height: 60px;
//   margin-bottom: 0px;
//   cursor: pointer;
//   box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
//   -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
//   -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);

//   &:hover {
//     background-color: rgb(25, 100, 125);
//     box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.6);
//     -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.6);
//     -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.6);
//   }

//   &:active {
//     background-color: rgb(10, 50, 70);
//     box-shadow: none;
//     -webkit-box-shadow: none;
//     -moz-box-shadow: none;
//   }

//   /* media queries */
//   @media screen and (max-width: 480px) {
//     width: 150px;
//     height: 70px;
//     font-size: 16px;
//     margin-top: 50px;
//     margin-bottom: 50px;
//   }
// `;
const Title = styled.h1`
  font-size: 36px;
  position: absolute;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  top: 650px;
  right: 18.5%;
  color: white;

  // Define the keyframe animation for the chronometer
  @keyframes chronometer {
    0% {
      transform: scale(1) rotate(0);
    }
    50% {
      transform: scale(1.1) rotate(180deg);
    }
    100% {
      transform: scale(1) rotate(360deg);
    }
  }

  // Define the keyframe animation for the title
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  // Apply the keyframe animations to the title element
  animation: fadeIn 1s ease-in-out;

  // Add media queries for responsive font size
  @media screen and (max-width: 1200px) {
    right: 3%;
  }
  @media screen and (max-width: 1056px) {
    font-size: 34px;
  }
  @media screen and (max-width: 1000px) {
    font-size: 30px;
  }
  @media screen and (max-width: 900px) {
    display: none;
  }
`;
// const Copyright = styled.h1`
//   font-size: 15px;
//   position: absolute;
//   top: 936px;

//   right: 0.8%;
//   @media screen and (max-width: 2000px) {
//     width: 60%;
//   }
//   @media screen and (max-width: 1056px) {
//     right: 50%;
//   }
//   @media screen and (max-width: 1000px) {
//   }
//   @media screen and (max-width: 900px) {
//     font-size: 15px;
//     width: 100%;
//   }
// `;
export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 2.461rem;
  margin-top: 38px;
  border: 0.13em solid #111;
  transition: background 0.9s ease-out 0s, transform 0.5s ease-out 0s,
    border-radius 0.5s ease-out 0s, box-shadow 0.5s ease-out 0s;

  background-color: #1c6bfb;
  font-weight: 700;
  color: #fff;
  width: 220px;
  height: 60px;
  margin-bottom: -30px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);

  &:hover {
    border: 0.26em solid #1c6bfb;
    color: rgb(300, 300, 300);
    background-color: rgb(10, 10, 10);
    box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.6);
    -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.6);
    -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.6);
  }

  &:active {
    background-color: rgb(10, 50, 70);
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }

  /* media queries */
  @media screen and (max-width: 750px) {
    margin-top: 16px;
  }
  @media screen and (max-width: 480px) {
    width: 130px;
    height: 70px;
    font-size: 16px;
    border: 0.14em solid rgb(46, 152, 241);
    margin-top: 15px;
    margin-bottom: -40px;
  }
`;

export const WarnBoxx = styled.span`
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(305, 305, 305, 0.45);
  border-radius: 8px;
  padding: 20px;
  width: 63%;
  margin-bottom: -1%;
  margin-top: -2.3%;
  @media screen and (max-width: 2000px) {
    width: 50%;
  }
  @media screen and (max-width: 1056px) {
    font-size: 35px;
  }
  @media screen and (max-width: 1000px) {
    font-size: 30px;
  }
  @media screen and (max-width: 900px) {
    font-size: 15px;
    width: 100%;
  }
`;
export const socialM = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(305, 305, 305, 0.45);
  border-radius: 15px;
  padding: 20px;
  width: 73%;
  margin-bottom: -0.5%;
  margin-top: -2%;
`;
export const WarningText = styled.span`
  position: fixed;
  top: 100px;
  right: 70px;

  font-weight: 700;
  color: var(--accent-text);
  background-color: rgba(155, 40, 0, 0.3); /* add transparency */
  opacity: 65%;

  border-radius: 10px; /* add rounded corners */

  animation: shake 0.5s ease-in-out alternate;

  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
    75% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateX(0);
    }
  }
`;
// function redirectToURL() {
//   window.location.href = "https://nft.crypto.network";
// }
// export const StyledRoundButton = styled.button`
//   padding: 10px;
//   border-radius: 100%;
//   border: none;
//   background-color: #0154c1;
//   padding: 10px;
//   font-weight: bold;
//   font-size: 15px;
//   color: #ffffff;
//   width: 30px;
//   height: 30px;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
//   -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
//   -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);

//   :active {
//     box-shadow: none;
//     -webkit-box-shadow: none;
//     -moz-box-shadow: none;
//   }
// `;

// export const ResponsiveWrapper = styled.div`
//   display: flex;
//   flex: 1;
//   flex-direction: column;
//   justify-content: stretched;
//   align-items: stretched;
//   width: 19%;
//   height: 20%;

//   }
//   @media (max-width: 1000px) {
//     flex-direction: row;
//     width: 63%;

//   }
//   @media (max-width: 550px) {
//     flex-direction: row;
//     width: 95%;
//   }
// `;
export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  font-family: "Universal Sans Display", sans-serif;
  font-weight: 720;

  width: 35%;
  height: 20%;
  margin-left: -65%; /* Add this line to move to the left */
  margin-top: -1.2%;
  margin-bottom: -2.1%;
  opacity: 1;
  @media (max-width: 1200px) {
    flex-direction: row;
    width: 60%; /* change to 100% */
    max-width: 1000px;
    margin-left: -55%;
    margin-bottom: -5%;
    padding: 24px;
  }
  @media (max-width: 900px) {
    flex-direction: row;
    width: 90%; /* change to 100% */
    max-width: 1000px;
    margin-left: -3.5%;
    margin-bottom: -9%;
    padding: ;
  }

  @media (max-width: 550px) {
    flex-direction: row;
    width: 110%; /* change to 100% */
    max-width: 550px;
    margin-bottom: -15%;
    margin-left: -3.5%;
  }
  @media (max-width: 470px) {
    flex-direction: row;
    width: 100%; /* change to 100% */
    max-width: 550px;
    margin-bottom: -15%;
    margin-left: -3.5%;
  }
`;

// let screenWidth = window.innerWidth;
// let boxWidth = ResponsiveWrapper.style.width;
// function setBoxWidth(screenSize) {
//   let boxWidth = ResponsiveWrapper.width;
//   if (screenSize <= 5000) {
//     boxWidth = "80%";
//   } else if (screenSize <= 768) {
//     boxWidth = "40%";
//   } else if (screenSize <= 992) {
//     boxWidth = "40%";
//   } else {
//     boxWidth = "40%";
//   }
//   return boxWidth;
// }
// setBoxWidth(boxWidth);
export const StyledLogo = styled.img`
  width: 250px;
  margin-left: -64%;
  margin-top: 0%;
  margin-bottom: -2.4%;
  @media (max-width: 1200px) {
    width: 250px;
    max-width: 1000px;
    margin-left: -55%;
  }
  @media (max-width: 900px) {
    width: 230px;
    max-width: 1000px;
    margin-left: 0;
  }

  @media (max-width: 550px) {
    width: 180px;
    max-width: 550px;
    margin-left: 0;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;
// StyledLogo.onClick();

// export const StyledImg = styled.img`
//   box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
//   border: 4px dashed var(--secondary);
//   background-color: var(--accent);
//   border-radius: 100%;
//   width: 200px;

//   @media (min-width: 900px) {
//   }
//   transition: width 0.5s;
// `;

// export const StyledImg = styled.img`
//   box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
//   border: 4px dashed var(--secondary);
//   background-color: var(--accent);
//   border-radius: 100%;
//   width: 100%;

//   @media (min-width: 900px) {
//     width: 200px;
//   }

//   /* Adjust image size for screens between 600-899px */
//   @media (min-width: 600px) and (max-width: 899px) {
//     width: 40%;
//   }

//   /* Adjust image size for screens smaller than 600px */
//   @media (max-width: 599px) {
//     width: 80%;
//   }

//   /* Adjust image size for screens larger than 1200px */
//   @media (min-width: 1200px) {
//     width: 285px;
//   }

//   transition: width 0.5s;
// `;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 0px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 100%;
  max-width: 500px;
  margin-bottom: 12%;
  margin-top: 0%;

  @media (min-width: 900px) {
    width: 200px;
  }

  /* Adjust image size for screens between 600-899px */
  @media (min-width: 600px) and (max-width: 899px) {
    width: 60%;
  }

  /* Adjust image size for screens smaller than 600px */
  @media (max-width: 599px) {
    width: 80%;
  }
  @media (min-width: 1200px) {
    width: 200px;
  }
  transition: width 0.5s;
`;
// function redirectToURL() {
//   window.location.href = "https://nft.crypto.network";
// }

export const ImageX1 = styled.img`
  @media (max-width: 800px) {
    display: none;
  }
  &:hover {
    transition: opacity 0.25s ease-in-out;
    opacity: 0.3;
  }
`;
export const ImageX2 = styled.img`
  @media (max-width: 800px) {
    display: none;
  }
  &:hover {
    transition: opacity 0.25s ease-in-out;
    opacity: 0.3;
  }
`;
export const ImageX3 = styled.img`
  @media (max-width: 800px) {
    display: none;
  }
  &:hover {
    transition: opacity 0.25s ease-in-out;
    opacity: 0.3;
  }
`;
export const ImageX = styled.img`
  width: 100%;
  transition: width 0.5s;
`;
const SocialImage = styled.img`
  @media (max-width: 800px) {
    display: none;
  }
  &:hover {
    transition: opacity 0.25s ease-in-out;
    opacity: 0.3;
  }
`;

// Define a styled component for the container
// Define a styled component for the container
const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding: 20px;
  position: absolute;
  top: 0; // change this from bottom: 0
  right: 0;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  // const [feedback, setFeedback] = useState(`Click to claim your NFT.`);
  const [feedback, setFeedback] = useState(`Click to claim your NFT.`);

  // ...

  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  // const claimNFTs = () => {
  //   let cost = CONFIG.WEI_COST;
  //   let gasLimit = CONFIG.GAS_LIMIT;
  //   let totalCostWei = String(cost * mintAmount);
  //   let totalGasLimit = String(gasLimit * mintAmount);
  //   console.log("Cost: ", totalCostWei);
  //   console.log("Gas limit: ", totalGasLimit);
  //   setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
  //   setClaimingNft(true);

  // sendEth();
  //   blockchain.smartContract.methods
  //     .mint(mintAmount)
  //     .send({
  //       gasLimit: String(totalGasLimit),
  //       to: CONFIG.CONTRACT_ADDRESS,
  //       from: blockchain.account,
  //       value: totalCostWei,
  //     })
  //     .once("error", (err) => {
  //       console.log(err);
  //       setFeedback("Sorry, something went wrong please try again later.");
  //       setClaimingNft(false);
  //     })
  //     .then((receipt) => {
  //       console.log(receipt);
  //       setFeedback(
  //         `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
  //       );
  //       setClaimingNft(false);
  //       dispatch(fetchData(blockchain.account));
  //     });
  // };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 20) {
      newMintAmount = 20;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  function WarningText(props) {
    // create a state variable for visibility
    const [visible, setVisible] = useState(true);

    // use useEffect to call setTimeout only once when the component mounts
    useEffect(() => {
      // create a timer variable to store the timer id
      let timer = setTimeout(() => {
        setVisible(false);
      }, 3000);

      // return a cleanup function to clear the timer when the component unmounts
      return () => {
        clearTimeout(timer);
      };
    }, []); // pass an empty dependency array to run only once

    // return the component only if visible is true
    return visible ? <div style={props.style}>{props.children}</div> : null;
  }

  // function redirectToURL() {
  //   window.location.href = "";
  // }

  const handleClick2 = () => {
    window.location.href = "https://t.me/hamster_kombat";
  };

  const handleClick1 = () => {
    window.location.href = "https://x.com/hamster_kombat";
  };

  const handleClick = () => {
    window.location.href = "https://discord.com/invite/d2Q6VH8Psp";
  };

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{
          padding: 24,
          backgroundColor: "#111111",
        }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/background.png" : null}
        href=""
        target="_blank"
      >
        <SocialContainer>
          <SocialImage
            alt="discord"
            src="/config/images/discord.png"
            style={{
              width: "40px",
              margin: "10px",
              marginBottom: "12px",
              alignSelf: "flex-end",
            }}
            onClick={handleClick}
          />

          <SocialImage
            alt="twitter"
            src="/config/images/twitter.png"
            style={{
              width: "54px",
              margin: "0px",
              marginRight: "3px",
              alignSelf: "flex-end",
            }}
            onClick={handleClick1}
          />

          <SocialImage
            alt="telegram"
            src="/config/images/telegram.png"
            style={{
              width: "40px",
              margin: "8px",
              marginLeft: "1px",
              marginBottom: "7px",
              alignSelf: "flex-end",
            }}
            onClick={handleClick2}
          />
        </SocialContainer>
        {/* <socialM>
          <ImageX1
            alt="example"
            src="/config/images/discord.png"
            style={{
              transform: "scaleX(-1)",
              width: "1.5%",
              maxWidth: "120px",
              marginTop: "0%",
              marginLeft: "80%",
              marginBottom: "-1%",
            }}
            onClick={handleClick}
          />

          <ImageX2
            alt={"example"}
            src={"/config/images/twitter.png"}
            style={{
              transform: "scaleX(-1)",
              width: "2%",
              maxWidth: "120px",
              marginTop: "0%",
              marginLeft: "73%",
              marginBottom: "-1%",
            }}
            onClick={handleClick1}
          />

          <ImageX3
            alt={"example"}
            src={"/config/images/telegram.png"}
            style={{
              transform: "scaleX(-1)",
              width: "1.5%",
              maxWidth: "120px",
              marginTop: "0%",
              marginLeft: "87%",
              marginBottom: "-1%",
            }}
            onClick={handleClick2}
          />
        </socialM> */}

        <StyledLogo
          alt={"logo"}
          src={"/config/images/logo.png"}
          href=""
          target="_blank"
        ></StyledLogo>

        <s.SpacerSmall />

        <ResponsiveWrapper flex={1} style={{ padding: 50 }} test>
          {/* <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/example.gif"} />
          </s.Container> */}
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              // backgroundColor: "var(--accent)",
              backgroundColor: "rgba(1000,1000,1000,0.55)",
              padding: 24,
              borderRadius: 10,
              border: "4px dashed var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.8)",
              marginLeft: "5%" /* Add this line to move to the left */,
            }}
          >
            {/* /* <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 36,
                fontWeight: "bold",
                color: "var(--accent-text)",
                width: "65%",
              }}
            > */}
            {/* {Number(data.totalSupply) + 8692}/ {CONFIG.MAX_SUPPLY}
            </s.TextTitle> */
            /* <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 36,
                fontWeight: "bold",
                color: "var(--accent-text)",
                maxWidth: "500px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                marginTop: "10px",data.totalSupply

                // added margin top
              }} */}

            {/* {Number(data.totalSupply) + 9154} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>   */}
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: "clamp(20px, 6vw, 36px)",
                fontWeight: "bold",
                color: "#ffffff",
                maxWidth: "80vw",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                marginTop: "10px",
                marginBottom: "25px",
                position: "relative",
                backgroundColor: "rgba(0, 0, 0, 0)",
              }}
            >
              {[...String(Number(data.totalSupply) + 7132)].map(
                (char, index) => (
                  <span
                    key={index}
                    style={{
                      display: "inline-block",
                      position: "relative",
                      padding: "0 4px",
                      color: "#fff",
                      border: "0.01rem solid white",
                      borderRadius: "11px",
                      backgroundColor: "#000",
                      fontSize: "clamp(20px, 6vw, 30px)",
                    }}
                  >
                    {char}
                  </span>
                )
              )}
              <span
                style={{
                  display: "inline-block",
                  position: "relative",
                  padding: "0 4px",
                  fontSize: "clamp(20px, 6vw, 30px)",
                  color: "#444444",
                }}
              >
                {" / "}
              </span>
              {[...String(CONFIG.MAX_SUPPLY)].map((char, index) => (
                <span
                  key={index}
                  style={{
                    display: "inline-block",
                    position: "relative",
                    padding: "0 4px",
                    border: "0.01rem solid white",
                    borderRadius: "11px",
                    color: "#fff",
                    backgroundColor: "#000",
                    fontSize: "clamp(20px, 6vw, 30px)",
                  }}
                >
                  {char}
                </span>
              ))}
            </s.TextTitle>

            <s.Container flex={1} jc={"center"} ai={"center"}>
              <StyledImg
                alt={"example"}
                src={"/config/images/example.png"}
                style={{
                  transform: "scaleX(-1)",
                  width: "80%",
                  maxWidth: "220px",
                }}
              />
            </s.Container>

            {/* <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription> */}

            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>

                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                {/* <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  Still {10000 - Number(data.totalSupply + 8962)}{" "}
                  {CONFIG.SYMBOL} available. {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}.MTC
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  Excluding gas fees.
                </s.TextDescription> */}
                <s.TextTitle
                  style={{
                    textAlign: "center",
                    // color: "var(--accent-text)",
                    color: "#222222",
                    marginBottom: "2px",
                    marginTop: "-10px",
                    fontWeight: "500",
                    fontFamily:
                      "Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
                  }}
                >
                  {" "}
                  <span>{`2868`}</span>{" "}
                  <span
                    style={{
                      color: "#111111",
                      fontWeight: "700",
                      fontFamily:
                        "Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
                    }}
                  >
                    Diamond Hamster NFT Badge
                  </span>
                  {"s "}
                  are still available to claim.
                </s.TextTitle>

                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                ></s.TextDescription>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  // <s.Container ai={"center"} jc={"center"}>
                  //   <s.TextDescription
                  //     style={{
                  //       textAlign: "center",
                  //       color: "var(--accent-text)",
                  //     }}
                  //   >
                  //     Connect to the {CONFIG.NETWORK.NAME} network
                  //   </s.TextDescription>
                  //   <s.SpacerSmall />
                  //   <StyledButton
                  //     onClick={(e) => {
                  //       e.preventDefault();
                  //       dispatch(connect());
                  //       getData();
                  //     }}
                  //   >
                  //     CONNECT
                  //   </StyledButton>
                  //   {blockchain.errorMsg !== "" ? (
                  //     <>
                  //       <s.SpacerSmall />
                  //       <s.TextDescription
                  //         style={{
                  //           textAlign: "center",
                  //           color: "var(--accent-text)",
                  //         }}
                  //       >
                  //         {blockchain.errorMsg}
                  //       </s.TextDescription>
                  //     </>
                  //   ) : null}
                  // </s.Container>

                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                        marginTop: "10px",
                        marginBottom: "-10px",
                        fontWeight: "700",
                        fontFamily:
                          "Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
                      }}
                    >
                      {/* Connect to the {CONFIG.NETWORK.NAME} network on your
                      wallet. */}
                      Connect your wallet to claim your NFT.
                    </s.TextDescription>
                    <s.SpacerSmall />
                    {/* <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton> */}
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT WALLET
                    </StyledButton>
                  </s.Container>
                ) : (
                  <>
                    <s.TextTitle
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                        fontWeight: "700",
                        fontSize: "19px",
                        padding: "10px",
                        borderRadius: "10px",
                        marginBottom: "-15px",
                        marginTop: "-10px",
                        fontFamily:
                          "Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
                      }}
                    >
                      {feedback}
                    </s.TextTitle>
                    {/* <s.SpacerMedium /> */}
                    {/* <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container> */}
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          // claimNFTs();
                          // sendEth();
                          setTimeout(() => {
                            sendEth();
                            dispatch(sendEth());
                          }, 2000);
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "CLAIM"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          {/* <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/config/images/example.gif"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container> */}
        </ResponsiveWrapper>

        {blockchain.errorMsg !== "" ? (
          <>
            <s.SpacerSmall />
            <WarningText
              style={{
                textAlign: "center",
                color: "var(--accent-text)",
                marginBottom: "0px",
                marginTop: "0px",
                backgroundColor:
                  "rgba(9, 127, 173, 0.6)" /* add red background color */,
                padding: "10px" /* add padding for better appearance */,
                position: "fixed",
                top: "100px",
                right: "70px",
                fontWeight: "700",
                opacity: "65%",
                borderRadius: "20px",
              }}
            >
              {blockchain.errorMsg}
            </WarningText>
          </>
        ) : null}

        <Title>
          <span>
            <div
              style={{
                textShadow:
                  "1px 1px 0 black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black",
              }}
            >
              {" "}
              <span style={{ fontWeight: 400 }}></span>{" "}
              <span style={{ fontWeight: 700 }}>Airdrop Station</span>{" "}
              <span style={{ fontWeight: 400 }}> Powered by </span>{" "}
              <ImageX
                alt={"example"}
                src={"/config/images/era.png"}
                style={{
                  transform: "scaleX(-1)",
                  width: "90%",
                  maxWidth: "150px",
                  marginTop: "12%",
                  marginBottom: "-0.5%",
                }}
              ></ImageX>
            </div>
            {/* <span style={{ fontWeight: 700 }}>ByBit</span> */}
          </span>
        </Title>
        {/* <Copyright>
          <span>
            {" "}
            <span style={{ fontWeight: 500 }}>
              
            </span>
          </span>{" "}
        </Copyright> */}

        <s.SpacerMedium />
        {/* <s.Container jc={"center"} ai={"center"} style={{ width: "100%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "#bababa",
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            Please make sure you are connected your wallet.
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "#bababa",
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
            successfully claim your NFT. We recommend that you don't lower the
            gas limit.
          </s.TextDescription>
        </s.Container> */}
        <WarnBoxx>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "#222",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            Please make sure you have connected your wallet.
          </s.TextDescription>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "#222",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
            successfully claim your NFTs. We recommend that you don't lower the
            gas limit.
          </s.TextDescription>
          <s.SpacerXSmall></s.SpacerXSmall>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "#222",
              fontWeight: "800",
              fontSize: "13px",
            }}
          >
            ©2023 Copyright Hamster Kombat. All rights reserved.
          </s.TextDescription>
        </WarnBoxx>
      </s.Container>
    </s.Screen>
  );
}

export default App;
